import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import { Events } from "../templates/news-and-blogs-template";
import placeholder_cross from "../assets/images/placeholder_cross.svg";
import SalesQuestion from "../components/SalesQuestion";
import { pushSAEvent } from "../helpers";

export default ({
    data,
    location,
    pageContext
}) => {
    pushSAEvent("visit_demo");

    const [hasLoaded, setHasLoaded] = React.useState(false);

    React.useEffect(() => {
        setHasLoaded(true);
    }, []);

    React.useEffect(() => {
        const onHubspotMessage = (evt) => {
            if (evt?.data?.meetingBookSucceeded) {
                pushSAEvent("submit_demo");
            }
        }

        window.addEventListener('message', onHubspotMessage);

        return () => window.removeEventListener('message', onHubspotMessage);
    }, []);

    const isLocaleDe = () => pageContext.locale === 'de';

    const getTitle = () => (
        <>
            {isLocaleDe() ? (
                <>
                    <h1 className="font-bold size-100 is-transparent text-stroke-white line-height-60">
                        Überzeugen sie sich
                    </h1>
                    <h2 className="font-variable font-width-variation-870 size-100 is-white margin-bottom-40">
                        von uns
                    </h2>
                </>
            ) : (
                <>
                    <h1 className="font-bold size-100 is-transparent text-stroke-white line-height-60">
                        sit back & let us
                    </h1>
                    <h2 className="font-variable font-width-variation-870 size-100 is-white margin-bottom-40">
                        impress you
                    </h2>
                </>
            )}
        </>
    );

    const getParagraph = () => (
        <>
            {isLocaleDe() ? (
                <p className="size-40 is-white">Buchen sie Ihren Termin für<br /> eine Frends Demo</p>
            ) : (
                <p className="size-40 is-white">Book a Frends demo with a<br /> timeslot that suits you.</p>
            )}
        </>
    );

    const getForm = () => (
        <>
            {isLocaleDe() ? (
                <div class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/frends/frends-demo-dach?embed=true"></div>
            ) : (
                <div class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/frends/frends-demo?embed=true"></div>
            )}
        </>
    );

    return (
        <Layout 
            title="Demo"
            pageContentId="demo-content" 
            transparentNavigation={true} 
            isDemo={hasLoaded} 
            location={location}
            language={pageContext.locale}
        >
            <main id="demo-content" className="bg-grey" style={{ marginTop: "-80px" }}>
                <section className="hero is-hidden-mobile is-medium" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            {getTitle()}

                            {getParagraph()}
                        </div>
                    </div>
                </section>
                <section className="section is-hidden-mobile">
                    <div style={{ zIndex: "1000" }}>
                        {getForm()}
                    </div>
                </section>

                <section className="hero is-hidden-tablet is-fullheight margin-bottom-60 line-height-60">
                    <GatsbyImage
                        image={getImage(data.bg)}
                        style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
                        alt="" />
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            {getTitle()}

                            {getParagraph()}
                        </div>
                    </div>
                </section>
                <div className="is-hidden-tablet">
                    {getForm()}
                </div>

                {!isLocaleDe() && (
                    <>
                        <section className="section is-medium">
                            <div className="columns content">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <div className="columns has-text-centered">
                                        <div className="column">
                                            <Link to="/platform" className="is-green is-green-border-bottom read-more">explore all features</Link>
                                        </div>
                                        <div className="column">
                                            <Link to="/customers" className="is-pink is-pink-border-bottom read-more">see case studies</Link>
                                        </div>
                                        <div className="column">
                                            <Link to="/about-us" className="is-black is-black-border-bottom read-more">learn more about us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Events isLightGreyBackground events={data.allContentfulWebinarV2.edges} />
                    </>
                )}

                <SalesQuestion
                    isLightGrey
                    color="green"
                    email={isLocaleDe() ? 'samuel.farag@frends.com' : 'juha@frends.com'}
                    image={placeholder_cross}
                    name={isLocaleDe() ? 'Samuel Farag' :  'Juha Moisio'}
                    locale={pageContext.locale}
                />
            </main>
        </Layout>
    );
};

export const query = graphql`query DemoQuery {
    allContentfulWebinarV2(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          title
          text
          webinarUrl
          webinarStartTime
          webinarLanguage
          webinarEndTime
        }
      }
    }
    bg: file(relativePath: {eq: "images/new_purple_background2.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
  `

